<template>
  <div class="page-tabs">
    <div class="page-tab-header">
      <div
        v-for="(item, index) in tabs"
        :key="index"
        @click="setCurrent(item, index)"
        :class="current === index ? 'tab-active' : ''"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="page-tab-body">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "page-tab",
  props: {
    tabs: {
      type: Array,
      default: [],
    },
    current: {
      type: Number,
      default: 0,
    },
    bodyHeight: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    setCurrent(item, index) {
      this.$emit("change", index, item);
    },
  },
};
</script>
<style scoped>
.tab-active {
  color: #0cb1f3;
  border-bottom: 1px solid #0cb1f3;
 
}
.page-tab-header div {
  height: 29px;
}
.page-tab-header {
  margin-top: 10px;
  height: 30px;
  display: flex;
  justify-content: space-around;
}
.page-tabs {
  display: flex;
  flex-direction: column;
}
.page-tab-body {
  flex: 1;
  overflow-y: auto;
}
</style>